import { Checkbox, FormControl, FormControlLabel, Grid, Input, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import content from '../../../content/becomeMember/payment.json';
import { Control, Controller, useForm } from 'react-hook-form';
import FiraSans from '../../../fonts/Fira_Sans/firaSans';
import Colors from '../../../styles/colors';
import Dimensions, { extraNarrowWidth } from '../../../styles/dimensions';
import BecomeMemberBreadcrumbs from '../../becomeMemberBreadcrumbs';
// @ts-ignore
import RedErrorIcon from '../../../images/offer/error_red_warning_icon.svg';
// @ts-ignore
import BackArrowIcon from '../../../images/offer/arrow_back_green.svg';

interface Props {
	step: number;
	changeStep: (value: number) => void;
	goBack: () => void;
	goForward: () => void;
	control: Control;
	trigger: (name?: string | string[]) => Promise<boolean>;
	errors: Record<string, any>;
	getValues: () => Record<string, any>;
}

interface ClassProps {
	step: number;
}

const Payment = (props: Props) => {
	const { step, changeStep, goBack, goForward, control, trigger, errors, getValues } = props;
	const classes = useClasses({ step });
	const [isAccountHolder, setIsAccountHolder] = useState(true);

	const validateIBAN = (value: string) => {
		if (!value) return content.iban.required;

		const formattedIBAN = value.replace(/\s/g, '').toUpperCase();

		const ibanRegex = /^DE\d{2}[0-9A-Z]{18}$/;

		if (!ibanRegex.test(formattedIBAN)) {
			return content.iban.invalid;
		}

		return true;
	};

	const handleNext = React.useCallback(() => {
		const fieldsToValidate = ['iban'];

		if (!isAccountHolder) {
			fieldsToValidate.push('accountHolder');
		}

		trigger(fieldsToValidate).then((valid: boolean) => {
			if (valid) {
				goForward();
			}
		});
	}, [trigger, goForward, getValues, isAccountHolder]);

	return (
		<section className={classes.section}>
			<div className={classes.container}>
				<BecomeMemberBreadcrumbs step={step} changeStep={changeStep} />

				<h1 className={classes.header}>{content.title}</h1>

				<form onSubmit={(event) => event.preventDefault()}>
					<Grid container>
						<Grid item xs={12} classes={{ root: classes.gridItem }}>
							<FormControl fullWidth>
								<Controller
									name="iban"
									defaultValue=""
									control={control}
									rules={{
										validate: validateIBAN,
									}}
									as={
										<Input
											placeholder={content.iban.placeholder}
											classes={{
												root: classes.input,
												input: classes.input,
												underline: classes.inputUnderline,
											}}
										/>
									}
								/>
								{errors.iban ? (
									<div className={classes.errorContainer}>
										<img src={RedErrorIcon} alt="" loading="lazy" />
										<span className={classes.errorSpan}>{errors.iban.message}</span>
									</div>
								) : null}
							</FormControl>
						</Grid>

						<Grid item xs={12} classes={{ root: classes.checkboxGrid }}>
							<Controller
								name="isAccountHolder"
								defaultValue={true}
								control={control}
								as={
									<FormControlLabel
										control={
											<Checkbox
												color="primary"
												className={classes.checkbox}
												checked={isAccountHolder}
												onChange={(e) => {
													setIsAccountHolder(e.target.checked);
													return e.target.checked;
												}}
											/>
										}
										label={content.accountHolder.checkbox}
										className={classes.checkboxLabel}
									/>
								}
							/>
						</Grid>

						{!isAccountHolder ? (
							<Grid item xs={12} classes={{ root: classes.gridItem }}>
								<FormControl fullWidth>
									<Controller
										name="accountHolder"
										defaultValue=""
										control={control}
										rules={{
											required: content.accountHolder.required,
										}}
										as={
											<Input
												placeholder="Kontoinhaber"
												classes={{
													root: classes.input,
													input: classes.input,
													underline: classes.inputUnderline,
												}}
											/>
										}
									/>
									{errors.accountHolder ? (
										<div className={classes.errorContainer}>
											<img src={RedErrorIcon} alt="" loading="lazy" />
											<span className={classes.errorSpan}>{errors.accountHolder.message}</span>
										</div>
									) : null}
								</FormControl>
							</Grid>
						) : null}
					</Grid>

					<div className={classes.formNavigationContainer}>
						<div className={classes.backContainer}>
							<img src={BackArrowIcon} alt="" loading="lazy" />
							<span className={classes.backSpan} onClick={goBack}>
								{content.navigation.back}
							</span>
						</div>
						<button className={classes.button} onClick={handleNext}>
							{content.navigation.next}
						</button>
					</div>
				</form>
			</div>
		</section>
	);
};

export default Payment;

const useClasses = makeStyles({
	section: (props: ClassProps) => ({
		display: props.step === 3 ? 'block' : 'none',
		paddingTop: '2.25em',
		paddingBottom: '2.75em',
		backgroundColor: Colors.offerBackgroundBlack,
		minHeight: 'calc(100vh - 5.25em)',
		boxSizing: 'border-box',
		'& .MuiInput-root:hover::before': {
			borderColor: Colors.whiteLightlyDimmed,
		},
		'@media (max-width: 631px)': {
			paddingLeft: '1em',
			paddingRight: '1em',
		},
	}),
	container: {
		maxWidth: extraNarrowWidth,
		margin: '0 auto',
	},
	header: {
		color: Colors.white,
		marginTop: '0.9em',
		marginBottom: '1.3em',
		textAlign: 'center',
		'@media (max-width: 959px)': {
			marginBottom: '0.9em',
		},
	},
	gridItem: {
		marginBottom: '2.75em',
	},
	checkboxGrid: {
		marginBottom: '1em',
	},
	input: {
		fontFamily: FiraSans.book,
		fontSize: Dimensions.smallText.fontSize,
		lineHeight: Dimensions.smallText.lineHeight,
		color: Colors.whiteLightlyDimmed,
		'&::placeholder': {
			opacity: 0.7,
		},
	},
	inputUnderline: {
		'&:after, &:before': {
			borderBottomColor: Colors.dividerGray,
		},
	},
	checkbox: {
		color: Colors.whiteLightlyDimmed,
		'&.Mui-checked': {
			color: Colors.limeGreen,
		},
	},
	checkboxLabel: {
		color: Colors.white,
		fontFamily: FiraSans.regular,
		'& .MuiFormControlLabel-label': {
			fontSize: Dimensions.smallText.fontSize,
		},
	},
	formNavigationContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: '2em',
		'@media (max-width: 599px)': {
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
	},
	backContainer: {
		cursor: 'pointer',
	},
	backSpan: {
		color: Colors.limeGreen,
		fontFamily: FiraSans.regular,
		fontSize: Dimensions.smallText.fontSize,
		lineHeight: Dimensions.smallText.lineHeight,
		marginLeft: '0.25em',
	},
	button: {
		backgroundColor: Colors.limeGreen,
		color: Colors.textBlack,
		fontFamily: FiraSans.medium,
		fontSize: Dimensions.smallText.fontSize,
		lineHeight: Dimensions.smallText.lineHeight,
		border: 0,
		borderRadius: '8px',
		outline: 0,
		cursor: 'pointer',
		padding: '0.875em 2.25em',
		'@media (max-width: 599px)': {
			display: 'block',
			width: '100%',
			marginTop: '2em',
		},
	},
	errorContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		fontFamily: FiraSans.regular,
		fontSize: Dimensions.extraTinyText.fontSize,
		lineHeight: Dimensions.extraTinyText.lineHeight,
		color: Colors.errorRed,
		marginTop: '0.5em',
	},
	errorSpan: {
		marginLeft: '0.6666666666666666em',
	},
});
