import { Breadcrumbs, makeStyles } from '@material-ui/core';
import React from 'react';
import FiraSans from '../fonts/Fira_Sans/firaSans';
import ArrowRight from '../images/offer/arrowRight';
import Colors from '../styles/colors';
import Dimensions from '../styles/dimensions';
import content from '../content/becomeMemberBreadcrumbs.json';
import { Link } from 'gatsby';
import Fjalla from '../fonts/Fjalla_One/fjalla';
// @ts-ignore
import LogoImg from '../images/Easyfit_logo.svg';
// @ts-ignore
import StepperIcon from '../images/offer/check_checkout_stepper.svg';

interface Props {
	step: number;
	changeStep: (value: number) => void;
}

const BecomeMemberBreadcrumbs = (props: Props) => {
	const { step, changeStep } = props;

	const classes = useClasses();

	const checkmarks = React.useMemo(() => {
		const elements: JSX.Element[] = [];
		for (let i = 0; i < content.breadcrumbs.length; i++) {
			if (i < step) {
				elements.push(
					<span key={i} className={classes.checkmarkContainer} onClick={() => changeStep(i)}>
						<img src={StepperIcon} alt="" loading="lazy" />
					</span>
				);
			} else if (i === step) {
				elements.push(
					<React.Fragment key={i}>
						<span className={classes.activeContainer}>
							<span className={classes.number}>{i + 1}</span>
						</span>
						<div className={classes.activeInnerContainer}>
							<span className={classes.activeTitle}>{content.breadcrumbs[i]}</span>
							{i + 1 < content.breadcrumbs.length && (
								<span className={classes.next}>
									{content.next}
									{content.breadcrumbs[i + 1]}
								</span>
							)}
						</div>
					</React.Fragment>
				);
			} else {
				elements.push(
					<span key={i} className={classes.inactiveContainer}>
						<span className={classes.number}>{i + 1}</span>
					</span>
				);
			}
		}
		return elements;
	}, [step]);

	return (
		<>

			<Breadcrumbs
				separator={<ArrowRight />}
				classes={{
					root: classes.breadcrumbRoot,
					ol: classes.breadcrumbList,
					separator: classes.breadcrumbSeparator,
				}}>
				{content.breadcrumbs.map((breadcrumb, key) => (
					<span
						key={key}
						onClick={() => {
							if (key < step) changeStep(key);
						}}
						style={
							key === step
								? { color: Colors.lightLimeGreen, fontFamily: FiraSans.book }
								: key > step
								? { fontFamily: FiraSans.regular }
								: { cursor: 'pointer' }
						}>
						{breadcrumb}
					</span>
				))}
			</Breadcrumbs>

			<div className={classes.bubbleBreadcrumbs}>{checkmarks}</div>
		</>
	);
};

export default BecomeMemberBreadcrumbs;

const useClasses = makeStyles({
	checkout: {
		color: Colors.whiteDimmed,
		textAlign: 'center',
		letterSpacing: '0.125em',
		fontFamily: FiraSans.regular,
		fontSize: Dimensions.extraTinyText.fontSize,
		lineHeight: Dimensions.extraTinyText.lineHeight,
		marginTop: 0,
		marginBottom: '1.3333333333333333em',
	},
	breadcrumbRoot: {
		color: Colors.whiteLightlyDimmed,
		letterSpacing: '0.03333333333333333em',
		fontFamily: FiraSans.semiBold,
		fontSize: Dimensions.extraTinyText.fontSize,
		lineHeight: Dimensions.extraTinyText.lineHeight,
		'@media (max-width: 599px)': {
			display: 'none',
		},
	},
	breadcrumbList: {
		justifyContent: 'center',
	},
	breadcrumbSeparator: {
		margin: '0 1.3333333333333333em',
	},
	logoLink: {
		width: '13.5em',
		display: 'block',
		margin: '0 auto',
		marginBottom: '2.625em',
	},
	bubbleBreadcrumbs: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		'@media (min-width: 600px)': {
			display: 'none',
		},
	},
	checkmarkContainer: {
		width: '32px',
		height: '32px',
		backgroundColor: 'white',
		borderRadius: '50%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
	},
	activeContainer: {
		width: '31px',
		height: '31px',
		border: `1px solid ${Colors.lightLimeGreen}`,
		borderRadius: '50%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
	},
	inactiveContainer: {
		width: '31px',
		height: '31px',
		border: `1px solid ${Colors.dividerGray}`,
		borderRadius: '50%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
	},
	number: {
		color: Colors.white,
		fontFamily: Fjalla.regular,
		fontSize: Dimensions.extraTinyText.fontSize,
		lineHeight: Dimensions.extraTinyText.lineHeight,
		transform: 'translateY(1px)',
	},
	activeInnerContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	activeTitle: {
		color: Colors.lightLimeGreen,
		fontFamily: FiraSans.semiBold,
		fontSize: Dimensions.tinyText.fontSize,
		lineHeight: Dimensions.tinyText.lineHeight,
	},
	next: {
		color: Colors.whiteLightlyDimmed,
		fontFamily: FiraSans.regular,
		fontSize: Dimensions.extraTinyText.fontSize,
		lineHeight: Dimensions.extraTinyText.lineHeight,
		letterSpacing: '0.03333333333333333em',
	},
});
