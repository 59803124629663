import { Checkbox, FormControl, FormControlLabel, Grid, Input, makeStyles } from '@material-ui/core';
import React from 'react';
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form';
import content from '../../../content/becomeMember/introduction.json';
import FiraSans from '../../../fonts/Fira_Sans/firaSans';
import Colors from '../../../styles/colors';
import Dimensions, { narrowWidth } from '../../../styles/dimensions';
import BecomeMemberBreadcrumbs from '../../becomeMemberBreadcrumbs';
// @ts-ignore
import RedErrorIcon from '../../../images/offer/error_red_warning_icon.svg';

interface Props {
	step: number;
	changeStep: (value: number) => void;
	goForward: () => void;
	control: Control<Record<string, any>>;
	trigger: (name?: string | string[]) => Promise<boolean>;
	errors: DeepMap<Record<string, any>, FieldError>;
	getValues: (value?: string | string[]) => string | object;
}

interface ClassProps {
	step: number;
}

const Introduction = (props: Props) => {
	const { step, changeStep, goForward, control, trigger, errors, getValues } = props;

	const classes = useClasses({ step });

	const [selectedValue, setSelectedValue] = React.useState<string | null>(null);

	const handleCheckboxChange = (value: string) => {
		setSelectedValue(value);

		control.setValue('gender', value);

		trigger('gender');
	};

	const handleNext = React.useCallback(() => {
		const fieldsToValidate = ['gender', ...content.fields.map((fieldName) => fieldName.name)];

		trigger(fieldsToValidate).then((valid: boolean) => {
			if (valid) {
				goForward();
			}
		});
	}, [step, trigger, goForward]);

	const validateBirthdate = React.useCallback((value: string) => {
		const birthdateRegex = /^\d{2}\.\d{2}\.\d{4}$/;
		if (!value) return false;

		if (!birthdateRegex.test(value)) {
			return content.dateErrorFormat;
		}

		const [day, month, year] = value.split('.').map(Number);

		const date = new Date(year, month - 1, day);
		const isValidDate = date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;

		if (!isValidDate) {
			return content.invalidDate;
		}

		const currentDate = new Date();
		const age = currentDate.getFullYear() - date.getFullYear();

		const monthDiff = currentDate.getMonth() - date.getMonth();
		const dayDiff = currentDate.getDate() - date.getDate();
		const adjustedAge = monthDiff < 0 || (monthDiff === 0 && dayDiff < 0) ? age - 1 : age;

		if (adjustedAge < 12) {
			return content.minAgeError;
		}

		if (adjustedAge > 100) {
			return content.maxAgeError;
		}

		return true;
	}, []);

	return (
		<section className={classes.section}>
			<div className={classes.container}>
				<BecomeMemberBreadcrumbs step={step} changeStep={changeStep} />

				<h1 className={classes.header}>{content.title}</h1>

				<form onSubmit={(event) => event.preventDefault()}>
					<Grid container>
						<Grid item xs={12} sm={12} classes={{ root: classes.gridItem }}>
							<Controller
								name="gender"
								defaultValue=""
								control={control}
								rules={{
									validate: () => selectedValue !== null || content.genderFieldError,
								}}
								render={({ field }) => (
									<>
										<FormControl fullWidth className={classes.radioGroup} error={!!errors.gender}>
											<FormControlLabel
												control={
													<Checkbox
														checked={selectedValue === content.gender[0].name}
														onChange={() => handleCheckboxChange(content.gender[0].name)}
														color="primary"
													/>
												}
												label={content.gender[0].placeholder}
											/>
											<FormControlLabel
												control={
													<Checkbox
														checked={selectedValue === content.gender[1].name}
														onChange={() => handleCheckboxChange(content.gender[1].name)}
														color="primary"
													/>
												}
												label={content.gender[1].placeholder}
											/>
											<FormControlLabel
												control={
													<Checkbox
														checked={selectedValue === content.gender[2].name}
														onChange={() => handleCheckboxChange(content.gender[2].name)}
														color="primary"
													/>
												}
												label={content.gender[2].placeholder}
											/>
										</FormControl>
										{errors.gender && !selectedValue ? (
											<div className={classes.errorContainerCheckboxes}>
												<img src={RedErrorIcon} alt="" loading="lazy" />
												<span className={classes.errorSpan}>{errors.gender.message}</span>
											</div>
										) : null}
									</>
								)}
							/>
						</Grid>
						{content.fields.map((item, key) => (
							<Grid key={key} item xs={12} sm={6} classes={{ root: classes.gridItem }}>
								<FormControl
									fullWidth
									style={key % 2 === 0 ? { paddingRight: '1em' } : { paddingLeft: '1em' }}
									classes={{ root: classes.formControlRoot }}>
									<Controller
										as={<Input />}
										defaultValue=""
										control={control}
										name={item.name}
										placeholder={item.placeholder}
										type={item.type}
										rules={
											item.name === 'birthdate'
												? {
														validate: validateBirthdate,
												  }
												: { required: item.fieldError }
										}
										classes={{
											root: classes.input,
											input: classes.input,
											underline: classes.inputUnderline,
										}}
									/>
								</FormControl>
								<div
									className={classes.errorContainer}
									style={
										key % 2 === 0
											? {
													paddingRight: '1.3333333333333333em',
													visibility: errors[item.name] ? 'visible' : 'hidden',
											  }
											: {
													paddingLeft: '1.3333333333333333em',
													visibility: errors[item.name] ? 'visible' : 'hidden',
											  }
									}>
									<img src={RedErrorIcon} alt="" loading="lazy" />
									<span className={classes.errorSpan}>
										{errors[item.name]
											? errors[item.name].message !== ''
												? errors[item.name].message
												: item.fieldError
											: ''}
									</span>
								</div>
							</Grid>
						))}
					</Grid>
					<div className={classes.formNavigationContainer}>
						<button className={classes.button} onClick={handleNext}>
							{content.continue}
						</button>
					</div>
				</form>
			</div>
		</section>
	);
};

export default Introduction;

const useClasses = makeStyles({
	section: (props: ClassProps) => ({
		display: props.step === 0 ? 'block' : 'none',
		paddingTop: '2.25em',
		paddingBottom: '2.75em',
		backgroundColor: Colors.offerBackgroundBlack,
		minHeight: 'calc(100vh - 5.25em)',
		boxSizing: 'border-box',
		'& .MuiInput-root:hover::before': {
			borderColor: Colors.whiteLightlyDimmed,
		},
		'@media (max-width: 631px)': {
			paddingLeft: '1em',
			paddingRight: '1em',
		},
	}),
	container: {
		maxWidth: narrowWidth,
		margin: '0 auto',
	},
	gridItem: {
		marginBottom: '2.75em',
	},
	radioGroup: {
		flexDirection: 'row',
		justifyContent: 'center',
		color: Colors.white,
	},
	radioLabel: {
		color: Colors.white,
	},
	formControlRoot: {
		boxSizing: 'border-box',
		'@media (max-width: 599px)': {
			padding: '0 !important',
		},
	},
	input: {
		fontFamily: FiraSans.book,
		fontSize: Dimensions.smallText.fontSize,
		lineHeight: Dimensions.smallText.lineHeight,
		color: Colors.whiteLightlyDimmed,
		'&::placeholder': {
			opacity: 0.7,
		},
	},
	inputUnderline: {
		'&:after, &:before': {
			borderBottomColor: Colors.dividerGray,
		},
	},
	header: {
		color: Colors.white,
		marginTop: '0.9em',
		marginBottom: '1.3em',
		textAlign: 'center',
		'@media (max-width: 959px)': {
			marginBottom: '0.9em',
		},
	},
	breadcrumbSeparator: {
		margin: '0 1.3333333333333333em',
	},
	formNavigationContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		alignItems: 'center',
		marginTop: '0.625em',
		'@media (max-width: 599px)': {
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
	},
	backContainer: {
		cursor: 'pointer',
	},
	backSpan: {
		color: Colors.limeGreen,
		fontFamily: FiraSans.regular,
		fontSize: Dimensions.smallText.fontSize,
		lineHeight: Dimensions.smallText.lineHeight,
		marginLeft: '0.25em',
	},
	button: {
		backgroundColor: Colors.limeGreen,
		color: Colors.textBlack,
		fontFamily: FiraSans.medium,
		fontSize: Dimensions.smallText.fontSize,
		lineHeight: Dimensions.smallText.lineHeight,
		border: 0,
		borderRadius: '8px',
		outline: 0,
		cursor: 'pointer',
		padding: '0.875em 2.25em',
		'@media (max-width: 599px)': {
			display: 'block',
			width: '100%',
			marginTop: '2em',
		},
	},
	errorContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		fontFamily: FiraSans.regular,
		fontSize: Dimensions.extraTinyText.fontSize,
		lineHeight: Dimensions.extraTinyText.lineHeight,
		color: Colors.errorRed,
		position: 'relative',
		top: '1.5625em',
		marginTop: '-1.0625em',
	},
	errorContainerCheckboxes: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'flex-start',
		fontFamily: FiraSans.regular,
		fontSize: Dimensions.extraTinyText.fontSize,
		lineHeight: Dimensions.extraTinyText.lineHeight,
		color: Colors.errorRed,
		position: 'relative',
		top: '1.5625em',
		marginTop: '-1.0625em',
	},
	errorSpan: {
		marginLeft: '0.6666666666666666em',
	},
});
