import React from 'react';
import { useForm } from 'react-hook-form';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { GoogleReviewsResponse } from './index';
import Introduction from '../components/sections/becomeMember/introduction';
import Address from '../components/sections/becomeMember/address';
import Bill from '../components/sections/becomeMember/bill';
import Payment from '../components/sections/becomeMember/payment';
import Feedback from '../components/sections/becomeMember/feedback';
import Overview from '../components/sections/becomeMember/overview';


interface Props {
	data: GoogleReviewsResponse;
	[key: string]: any;
}

const Offer = ({ data }: Props) => {
	const [step, setStep] = React.useState(0);

	const { control, handleSubmit, errors, trigger, clearErrors, getValues, watch, setValue } = useForm();

	const changeStep = React.useCallback(
		(value: number) => {
			setStep(value);
		},
		[step]
	);

	const goBack = React.useCallback(() => {
		clearErrors();
		setStep(step - 1);
	}, [step]);

	const goForward = React.useCallback(() => {
		setStep(step + 1);
	}, [step]);

	return (
		<Layout>
			<SEO title="Mitglied werden" />

			<Introduction
				step={step}
				changeStep={changeStep}
				goForward={goForward}
				control={control}
				trigger={trigger}
				errors={errors}
				getValues={getValues}
			/>
			<Address
				step={step}
				changeStep={changeStep}
				goBack={goBack}
				goForward={goForward}
				control={control}
				trigger={trigger}
				errors={errors}
				getValues={getValues}
			/>
			<Bill
				step={step}
				changeStep={changeStep}
				goBack={goBack}
				goForward={goForward}
				control={control}
				trigger={trigger}
				errors={errors}
				getValues={getValues}
				setValue={setValue}
			/>
			<Payment
				step={step}
				changeStep={changeStep}
				goBack={goBack}
				goForward={goForward}
				control={control}
				trigger={trigger}
				errors={errors}
				getValues={getValues}
			/>
			<Overview
				step={step}
				changeStep={changeStep}
				goBack={goBack}
				goForward={goForward}
				control={control}
				trigger={trigger}
				errors={errors}
				getValues={getValues}
			/>

			<Feedback step={step} />
		</Layout>
	);
};

export default Offer;
