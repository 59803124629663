import { Checkbox, FormControl, FormControlLabel, Grid, Input, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { Control, Controller, useForm } from 'react-hook-form';
import FiraSans from '../../../fonts/Fira_Sans/firaSans';
import Colors from '../../../styles/colors';
import Dimensions, { extraNarrowWidth } from '../../../styles/dimensions';
import BecomeMemberBreadcrumbs from '../../becomeMemberBreadcrumbs';
// @ts-ignore
import RedErrorIcon from '../../../images/offer/error_red_warning_icon.svg';
// @ts-ignore
import BackArrowIcon from '../../../images/offer/arrow_back_green.svg';

interface Props {
	step: number;
	changeStep: (value: number) => void;
	goBack: () => void;
	goForward: () => void;
	control: Control;
	trigger: (name?: string | string[]) => Promise<boolean>;
	errors: Record<string, any>;
	getValues: () => Record<string, any>;
}

interface ClassProps {
	step: number;
}

const Overview = (props: Props) => {
	const { step, changeStep, goBack, goForward, control, trigger, errors, getValues } = props;
	const classes = useClasses({ step });
	const [isPrivacyPolicy, setIsPrivacyPolicy] = useState(false);

	const handleNext = React.useCallback(() => {
		const fieldsToValidate = ['isPrivacyPolicy'];

		if (!isPrivacyPolicy) {
			fieldsToValidate.push('isPrivacyPolicy');
		}

		trigger(fieldsToValidate).then((valid: boolean) => {
			if (valid) {
				const formData = getValues();
				console.log('Formulardaten:', formData);
				goForward();
			}
		});
	}, [trigger, goForward, getValues, isPrivacyPolicy]);

	return (
		<section className={classes.section}>
			<div className={classes.container}>
				<BecomeMemberBreadcrumbs step={step} changeStep={changeStep} />

				<h1 className={classes.header}>Bitte prüfe deine Daten</h1>

				<h4 className={classes.header}>Widerrufsbelehrung</h4>
				<p className={classes.header}>
					Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen. Die
					Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsschlusses. Der Vertrag kommt mit der
					Annahme Ihres Online abgegebenen Angebotes durch die versandte Bestätigungsmail zustande. Um Ihr
					Widerrufsrecht auszuüben, müssen Sie uns mittels einer eindeutigen Erklärung (z. B. ein mit der Post
					versandter Brief, Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen,
					informieren. Zur Wahrung der Widerrufsfrist ist es erforderlich, dass Sie die Mitteilung über die
					Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
				</p>

				<form onSubmit={(event) => event.preventDefault()}>
					<Grid container>
						<Grid item xs={12} classes={{ root: classes.checkboxGrid }}>
							<Controller
								name="isPrivacyPolicy"
								defaultValue={false}
								control={control}
								rules={{ required: true }}
								render={(props) => (
									<FormControlLabel
										control={
											<Checkbox
												color="primary"
												className={classes.checkbox}
												checked={props.value}
												onChange={(e) => {
													const newValue = e.target.checked;
													setIsPrivacyPolicy(newValue);
													props.onChange(newValue);
												}}
											/>
										}
										label="Ich stimme den Allgemeinen Geschäftsbedingungen zu"
										className={classes.checkboxLabel}
									/>
								)}
							/>
						</Grid>
					</Grid>

					<div className={classes.formNavigationContainer}>
						<div className={classes.backContainer}>
							<img src={BackArrowIcon} alt="" loading="lazy" />
							<span className={classes.backSpan} onClick={goBack}>
								Zurück zum Bezahlung
							</span>
						</div>
						<button className={classes.button} onClick={handleNext}>
							Weiter
						</button>
					</div>
				</form>
			</div>
		</section>
	);
};

export default Overview;

const useClasses = makeStyles({
	section: (props: ClassProps) => ({
		display: props.step === 4 ? 'block' : 'none',
		paddingTop: '2.25em',
		paddingBottom: '2.75em',
		backgroundColor: Colors.offerBackgroundBlack,
		minHeight: 'calc(100vh - 5.25em)',
		boxSizing: 'border-box',
		'& .MuiInput-root:hover::before': {
			borderColor: Colors.whiteLightlyDimmed,
		},
		'@media (max-width: 631px)': {
			paddingLeft: '1em',
			paddingRight: '1em',
		},
	}),
	container: {
		maxWidth: extraNarrowWidth,
		margin: '0 auto',
	},
	header: {
		color: Colors.white,
		marginTop: '0.9em',
		marginBottom: '1.3em',
		textAlign: 'center',
		'@media (max-width: 959px)': {
			marginBottom: '0.9em',
		},
	},
	gridItem: {
		marginBottom: '2.75em',
	},
	checkboxGrid: {
		marginBottom: '1em',
	},
	input: {
		fontFamily: FiraSans.book,
		fontSize: Dimensions.smallText.fontSize,
		lineHeight: Dimensions.smallText.lineHeight,
		color: Colors.whiteLightlyDimmed,
		'&::placeholder': {
			opacity: 0.7,
		},
	},
	inputUnderline: {
		'&:after, &:before': {
			borderBottomColor: Colors.dividerGray,
		},
	},
	checkbox: {
		color: Colors.whiteLightlyDimmed,
		'&.Mui-checked': {
			color: Colors.limeGreen,
		},
	},
	checkboxLabel: {
		color: Colors.white,
		fontFamily: FiraSans.regular,
		'& .MuiFormControlLabel-label': {
			fontSize: Dimensions.smallText.fontSize,
		},
	},
	formNavigationContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: '2em',
		'@media (max-width: 599px)': {
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
	},
	backContainer: {
		cursor: 'pointer',
	},
	backSpan: {
		color: Colors.limeGreen,
		fontFamily: FiraSans.regular,
		fontSize: Dimensions.smallText.fontSize,
		lineHeight: Dimensions.smallText.lineHeight,
		marginLeft: '0.25em',
	},
	button: {
		backgroundColor: Colors.limeGreen,
		color: Colors.textBlack,
		fontFamily: FiraSans.medium,
		fontSize: Dimensions.smallText.fontSize,
		lineHeight: Dimensions.smallText.lineHeight,
		border: 0,
		borderRadius: '8px',
		outline: 0,
		cursor: 'pointer',
		padding: '0.875em 2.25em',
		'@media (max-width: 599px)': {
			display: 'block',
			width: '100%',
			marginTop: '2em',
		},
	},
	errorContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		fontFamily: FiraSans.regular,
		fontSize: Dimensions.extraTinyText.fontSize,
		lineHeight: Dimensions.extraTinyText.lineHeight,
		color: Colors.errorRed,
		marginTop: '0.5em',
	},
	errorSpan: {
		marginLeft: '0.6666666666666666em',
	},
});
