import { Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, makeStyles } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { de } from 'date-fns/locale';
import React, { useState, useEffect } from 'react';
import content from '../../../content/becomeMember/bill.json';
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form';
import FiraSans from '../../../fonts/Fira_Sans/firaSans';
import Colors from '../../../styles/colors';
import Dimensions, { extraNarrowWidth } from '../../../styles/dimensions';
import BecomeMemberBreadcrumbs from '../../becomeMemberBreadcrumbs';
// @ts-ignore
import RedErrorIcon from '../../../images/offer/error_red_warning_icon.svg';
// @ts-ignore
import BackArrowIcon from '../../../images/offer/arrow_back_green.svg';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

interface Props {
	step: number;
	changeStep: (value: number) => void;
	goBack: () => void;
	goForward: () => void;
	control: Control<Record<string, any>>;
	trigger: (name?: string | string[]) => Promise<boolean>;
	errors: DeepMap<Record<string, any>, FieldError>;
	getValues: () => Record<string, any>;
	setValue: (name: string, value: any) => void;
}

interface ClassProps {
	step: number;
}

const Bill = (props: Props) => {
	const { step, changeStep, goBack, goForward, control, trigger, errors, getValues, setValue } = props;
	const classes = useClasses({ step });

	const [selectedFlatrate, setSelectedFlatrate] = useState(false);
	const [selectedMonth, setSelectedMonth] = useState<Date | null>(null);
	const [minTrainingDate, setMinTrainingDate] = useState<Date | null>(null);
	const [maxTrainingDate, setMaxTrainingDate] = useState<Date | null>(null);

	const getNextMonth = () => {
		const today = new Date();
		const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);

		return nextMonth;
	};

	const getContractStartHelperText = () => {
		const nextMonth = getNextMonth();

		return content.dateFields.contractStart.helperTextTemplate
			.replace('{month}', content.monthNames[nextMonth.getMonth()])
			.replace('{year}', nextMonth.getFullYear().toString());
	};

	const getTrainingStartHelperText = () => {
		if (!minTrainingDate) return '';

		const day = minTrainingDate.getDate().toString().padStart(2, '0');
		const month = (minTrainingDate.getMonth() + 1).toString().padStart(2, '0');
		const year = minTrainingDate.getFullYear();

		return content.dateFields.trainingStart.helperTextTemplate
			.replace('{day}', day)
			.replace('{month}', month)
			.replace('{year}', year.toString());
	};

	useEffect(() => {
		if (selectedMonth) {

			const firstDayOfMonth = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), 1);

			setMinTrainingDate(firstDayOfMonth);
			setMaxTrainingDate(firstDayOfMonth);


			const formValues = getValues();
			if (
				!formValues.trainingStartDate ||
				formValues.trainingStartDate.getDate() !== 1 ||
				formValues.trainingStartDate.getMonth() !== firstDayOfMonth.getMonth()
			) {
				setValue('trainingStartDate', firstDayOfMonth);
			}
		}
	}, [selectedMonth, control]);


	const shouldDisableDate = (date: MaterialUiPickersDate) => {
		if (!date) return false; 

		return date.getDate() !== 1;
	};

	const handleNext = React.useCallback(() => {
		trigger(['useFlatrate', 'contractStartMonth', 'trainingStartDate']).then((valid: boolean) => {
			if (valid) {
				const formData = getValues();
				console.log('Podaci iz prethodnih koraka:', formData);

				goForward();
			}
		});
	}, [trigger, goForward, getValues]);

	return (
		<section className={classes.section}>
			<div className={classes.container}>
				<BecomeMemberBreadcrumbs step={step} changeStep={changeStep} />

				<h3 className={classes.header}>{content.title}</h3>

				<form onSubmit={(event) => event.preventDefault()}>
					<Grid container>
						<Grid item xs={12} classes={{ root: classes.gridItem }}>
							<Controller
								name="useFlatrate"
								defaultValue={false}
								control={control}
								render={({ onChange, value }) => (
									<FormControl fullWidth>
										<FormControlLabel
											control={
												<Checkbox
													checked={value}
													onChange={(e) => {
														onChange(e.target.checked);
														setSelectedFlatrate(e.target.checked);
													}}
													color="primary"
													className={classes.checkbox}
												/>
											}
											label={content.flatrateOption}
											className={classes.checkboxLabel}
										/>
										<FormHelperText className={classes.flatrateHelperText}>
											{content.flatrateDescription}
										</FormHelperText>
									</FormControl>
								)}
							/>
						</Grid>
					</Grid>

					<h3 className={classes.subHeader}>{content.trainingStartHeader}</h3>

					<MuiPickersUtilsProvider utils={DateFnsUtils} locale={de}>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6} classes={{ root: classes.gridItem }}>
								<Controller
									name="contractStartMonth"
									control={control}
									defaultValue={null}
									rules={{ required: content.dateFields.contractStart.required }}
									render={({ onChange, value }) => (
										<FormControl fullWidth>
											<DatePicker
												variant="inline"
												openTo="month"
												views={['year', 'month']}
												label={content.dateFields.contractStart.label}
												value={value}
												onChange={(date) => {
													onChange(date);
													setSelectedMonth(date as Date);
												}}
												minDate={getNextMonth()}
												maxDate={
													new Date(new Date().getFullYear() + 1, new Date().getMonth(), 1)
												}
												format={content.dateFields.contractStart.format}
												className={classes.datePicker}
												inputProps={{
													className: classes.datePickerInput,
												}}
											/>
											<FormHelperText className={classes.datePickerHelper}>
												{getContractStartHelperText()}
											</FormHelperText>
											{errors.contractStartMonth ? (
												<div className={classes.errorContainer}>
													<img src={RedErrorIcon} alt="" loading="lazy" />
													<span className={classes.errorSpan}>
														{errors.contractStartMonth.message}
													</span>
												</div>
											) : null}
										</FormControl>
									)}
								/>
							</Grid>
							<Grid item xs={12} sm={6} classes={{ root: classes.gridItem }}>
								<Controller
									name="trainingStartDate"
									control={control}
									defaultValue={null}
									rules={{
										required: content.dateFields.trainingStart.required,
										validate: (value) =>
											!!selectedMonth || content.dateFields.trainingStart.validate,
									}}
									render={({ onChange, value }) => (
										<FormControl fullWidth>
											<DatePicker
												variant="inline"
												label={content.dateFields.trainingStart.label}
												value={value}
												onChange={onChange}
												minDate={minTrainingDate}
												maxDate={maxTrainingDate}
												shouldDisableDate={shouldDisableDate}
												disabled={!selectedMonth}
												format={content.dateFields.trainingStart.format}
												className={classes.datePicker}
												inputProps={{
													className: classes.datePickerInput,
												}}
											/>
											<FormHelperText className={classes.datePickerHelper}>
												{getTrainingStartHelperText()}
											</FormHelperText>
											{errors.trainingStartDate && (
												<div className={classes.errorContainer}>
													<img src={RedErrorIcon} alt="" loading="lazy" />
													<span className={classes.errorSpan}>
														{errors.trainingStartDate.message}
													</span>
												</div>
											)}
										</FormControl>
									)}
								/>
							</Grid>
						</Grid>
					</MuiPickersUtilsProvider>

					<div className={classes.formNavigationContainer}>
						<div className={classes.backContainer}>
							<img src={BackArrowIcon} alt="" loading="lazy" />
							<span className={classes.backSpan} onClick={goBack}>
								{content.navigation.back}
							</span>
						</div>
						<button className={classes.button} onClick={handleNext}>
							{content.navigation.next}
						</button>
					</div>
				</form>
			</div>
		</section>
	);
};

export default Bill;

const useClasses = makeStyles({
	section: (props: ClassProps) => ({
		display: props.step === 2 ? 'block' : 'none',
		paddingTop: '2.25em',
		paddingBottom: '2.75em',
		backgroundColor: Colors.offerBackgroundBlack,
		minHeight: 'calc(100vh - 5.25em)',
		boxSizing: 'border-box',
		'& .MuiInput-root:hover::before': {
			borderColor: Colors.whiteLightlyDimmed,
		},
		'@media (max-width: 631px)': {
			paddingLeft: '1em',
			paddingRight: '1em',
		},
	}),
	container: {
		maxWidth: extraNarrowWidth,
		margin: '0 auto',
	},
	header: {
		color: Colors.white,
		marginTop: '0.9em',
		marginBottom: '1.3em',
		textAlign: 'center',
		'@media (max-width: 959px)': {
			marginBottom: '0.9em',
		},
	},
	subHeader: {
		color: Colors.white,
		marginTop: '1.5em',
		marginBottom: '1em',
		textAlign: 'center',
		fontSize: '1.5em',
	},
	gridItem: {
		marginBottom: '1.5em',
	},
	checkbox: {
		color: Colors.whiteLightlyDimmed,
		'&.Mui-checked': {
			color: Colors.limeGreen,
		},
	},
	checkboxLabel: {
		color: Colors.white,
		fontFamily: FiraSans.medium,
		'& .MuiFormControlLabel-label': {
			fontSize: Dimensions.smallText.fontSize,
		},
	},
	flatrateHelperText: {
		color: Colors.whiteLightlyDimmed,
		marginTop: '-0.5em',
		marginLeft: '2em',
		fontFamily: FiraSans.book,
		fontSize: Dimensions.extraTinyText.fontSize,
	},
	datePicker: {
		'& .MuiInputLabel-root': {
			color: Colors.whiteLightlyDimmed,
		},
		'& .MuiInput-underline:before': {
			borderBottomColor: Colors.dividerGray,
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottomColor: Colors.whiteLightlyDimmed,
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: Colors.limeGreen,
		},
	},
	datePickerInput: {
		color: Colors.whiteLightlyDimmed,
		fontFamily: FiraSans.book,
		fontSize: Dimensions.smallText.fontSize,
	},
	datePickerHelper: {
		color: Colors.whiteLightlyDimmed,
		marginTop: '0.5em',
		fontFamily: FiraSans.book,
		fontSize: Dimensions.extraTinyText.fontSize,
	},
	formNavigationContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: '2em',
		'@media (max-width: 599px)': {
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
	},
	backContainer: {
		cursor: 'pointer',
	},
	backSpan: {
		color: Colors.limeGreen,
		fontFamily: FiraSans.regular,
		fontSize: Dimensions.smallText.fontSize,
		lineHeight: Dimensions.smallText.lineHeight,
		marginLeft: '0.25em',
	},
	button: {
		backgroundColor: Colors.limeGreen,
		color: Colors.textBlack,
		fontFamily: FiraSans.medium,
		fontSize: Dimensions.smallText.fontSize,
		lineHeight: Dimensions.smallText.lineHeight,
		border: 0,
		borderRadius: '8px',
		outline: 0,
		cursor: 'pointer',
		padding: '0.875em 2.25em',
		'@media (max-width: 599px)': {
			display: 'block',
			width: '100%',
			marginTop: '2em',
		},
	},
	errorContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		fontFamily: FiraSans.regular,
		fontSize: Dimensions.extraTinyText.fontSize,
		lineHeight: Dimensions.extraTinyText.lineHeight,
		color: Colors.errorRed,
		marginTop: '0.5em',
	},
	errorSpan: {
		marginLeft: '0.6666666666666666em',
	},
});
